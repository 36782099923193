<template>
  <div class="artistsDetails bgc_f5f5f5">
    <div class="head_box">
      <div class="head">
        <router-link
          :to="{
            path: '/H5/art/authorDetails',
            query: {
              id: detail.Artist.Id,
              type: 1,
            },
          }"
        >
          <img v-lazy="detail.Artist.HeadImg" alt="" srcset="" class="head1" />
          <span>{{ detail.Artist.SupplierName }}</span>
          <!-- IsSign 0未签约 1 已签约  -->
          <img
            src="../../../assets/images/icon_qian.png"
            alt=""
            v-if="detail.Artist.IsSign == 1"
            class="icon_qian"
            srcset=""
          />
        </router-link>
      </div>
      <div
        v-if="route.artUserId == detail.Artist.StudentId"
        class="more"
        @click="hanldeMore"
      >
        ···
      </div>
    </div>
    <div class="imgs">
      <div v-for="(li, i) in listImgs" :key="i">
        <img
          v-if="!li.video"
          :src="li.src"
          @click="handlePreview(li.src)"
          alt=""
        />
        <div v-else class="video">
          <video
            :x5-playsinline="true"
            :webkit-playsinline="true"
            :playsinline="true"
            controls
          >
            <source :src="li.src" />
          </video>
        </div>
      </div>
    </div>
    <div class="cont">
      <h5>{{ detail.ArtDetail.GoodsName }}</h5>
      <p class="specs">{{ detail.ArtDetail.ArtSizeName }}</p>
      <div class="fbllz">
        <p>{{ detail.ArtDetail.CreateTime | dataYMD }}发表</p>
        <p>
          {{
            detail.ArtDetail.ViewCount == null ? 1 : detail.ArtDetail.ViewCount
          }}浏览
        </p>
        <p>
          {{
            detail.ArtDetail.CollectCount == null
              ? 0
              : detail.ArtDetail.CollectCount
          }}个赞
        </p>
      </div>
      <div class="price" v-if="detail.ArtDetail.DefaultPrice">
        <span>￥</span>{{ detail.ArtDetail.DefaultPrice }}
      </div>
      <div class="des">作品描述</div>
      <div class="content" v-html="detail.ArtDetail.Introduce"></div>
      <div
        style="height: 10px; background-color: #f5f5f5; margin: 20px -15px 0"
      ></div>
      <div class="works" v-if="detail.RecommendGoods.length > 0">
        <div class="title">为您推荐</div>
        <ul>
          <li v-for="(p, i) in detail.RecommendGoods" :key="i">
            <a
              :href="
                '/H5/art/artistsDetails?id=' +
                p.Id +
                '&artUserId=' +
                route.artUserId
              "
            >
              <img :src="p.CoverImg" class="coverImg" alt="" srcset="" />
              <!-- <p class="goodsName">{{ p.SupplierName }}</p> -->
              <p class="goodsName decimalPoint-2">{{ p.GoodsName }}</p>
              <div class="default">
                <p class="defaultPrice" v-if="p.DefaultPrice">
                  <span>￥</span>{{ p.DefaultPrice }}
                </p>
                <p v-if="p.CollectCount > 0">
                  <img src="@/assets/images/icon_heart_1.png" alt="" />
                  {{ p.CollectCount }}
                </p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div style="height: 54px; margin-top: 10px"></div>
    <div class="fixed_ship">
      <div class="ship">
        <img
          :src="
            require('@/assets/images/icon_heart_' +
              (detail.ArtDetail.IsCollect != 1 ? '3' : '4') +
              '.png')
          "
          @click="handleCollection"
          alt=""
        />
        <!-- <img src="../../../assets/images/icon_heart_2.png" alt="" /> -->
        <img
          src="@/assets/images/icon_ship.png"
          @click="handleShopCar"
          alt=""
        />
      </div>
      <div
        class="shop"
        @click="handleShop"
        v-if="detail.ArtDetail.DefaultPrice"
      >
        立即购买
      </div>
    </div>
    <van-action-sheet
      v-model="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @cancel="onCancel"
      @select="onSelect"
    />
  </div>
</template>

<script>
// import { queryArtExhibitionList } from "@/api/art";
// import { queryShopGoodsDetail } from "@/api/shop";
import {
  queryArtWorkDetail,
  cancleCollectArtWord,
  collectArtWork,
  artWorkDelete,
} from "@/api/art";
import noData from "@/components/noData";
import { Dialog, ImagePreview } from "vant";

export default {
  name: "index",
  components: { noData },
  computed: {},

  data() {
    return {
      show: false,
      actions: [{ name: "编辑" }, { name: "删除" }],
      loading: false,
      finished: false,
      listImgs: [],
      list: [],
      route: {},
      detail: {
        ArtDetail: {},
        Artist: {},
        RecommendGoods: [],
      },
      artUserId: "",
      recList: [],
    };
  },
  created() {
    this.artUserId = localStorage.getItem("artUserId");
    var that = this;
    window["callByAndroidParam"] = function (jsonStr) {
      if (jsonStr != "") {
        that.user.Id = jsonStr.data;
        that.getQueryUserInfo();
        that.getQuerySupplierByStudentId();
      } else {
        that.$message.error("登录失败");
      }
    };
  },
  mounted() {
    this.route = this.$route.query;
    this.getQueryArtWorkDetail();
  },
  methods: {
    handlePreview(src) {
      ImagePreview([src]);
    },
    handleShop() {
      let path = "/shop/goods/detail/" + this.route.id;
      this.$router.push(path);
    },
    onSelect(item) {
      if (item.name == "编辑") {
        let path =
          "/H5/art/artworkAdd?id=" +
          this.route.id +
          "&UserID=" +
          this.route.artUserId;
        this.$router.push(path);
      }
      if (item.name == "删除") {
        this.getArtWorkDelete();
      }
    },
    hanldeMore() {
      this.show = true;
    },
    onCancel() {
      // Toast('取消');
    },
    handleShopCar() {
      if (!this.route.artUserId) {
        this.callApp();
        return false;
      }
      let path = "/shop/ShopCar";
      this.$router.push(path);
    },
    // 收藏  /取消收藏
    async handleCollection() {
      if (this.route.artUserId > 0) {
        let parm = {
          studentId: this.route.artUserId,
          shopGoodsId: this.route.id,
        };
        let res = {};
        // IsCollect 0未收藏 1已收藏
        if (this.detail.ArtDetail.IsCollect != 1) {
          res = await collectArtWork(parm);
        } else {
          res = await cancleCollectArtWord(parm);
        }
        if (res.data.success == true) {
          // this.$message.success("操作成功");
          this.getQueryArtWorkDetail();
        } else {
          this.$message.error(res.msg);
        }
      } else {
        this.callApp();
        return false;
      }
    },
    // 删除
    async getArtWorkDelete() {
      if (!this.route.artUserId) {
        this.callApp();
        return false;
      }
      // let parm = {
      //   artId: this.route.id,
      // }
      let parm = "?artId=" + this.route.id;
      const res = await artWorkDelete(parm);
      if (res.data.success == true) {
        Dialog.alert({
          message: "删除成功",
          theme: "round-button",
          confirmButtonColor: "#ee0a24",
        }).then(() => {
          this.$router.go(-1); //返回上一层
        });

        // this.$message.success("操作成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    // 获取商品详情
    async getQueryArtWorkDetail() {
      // console.log(this.route.artUserId, "");
      let studentId = 0;
      if (this.route.artUserId > 0) {
        studentId = this.route.artUserId;
      } else {
        studentId = 0;
      }
      // let studentId = this.route.artUserId ? this.route.artUserId : studentId
      let parm = "?id=" + this.route.id + "&studentId=" + studentId;
      const res = await queryArtWorkDetail(parm);
      this.detail = res.data.response;
      this.detail.ArtDetail.Images = this.detail.ArtDetail.Images.split(",");
      this.detail.ArtDetail.Images.forEach((element) => {
        var flieArr = element.split(".");
        var suffix = flieArr[flieArr.length - 1];
        var videolist = ["mp4", "m2v", "mkv"];
        var result = videolist.some((item) => {
          return item == suffix;
        });
        this.listImgs.push({
          video: result,
          msrc: element,
          src: element,
          h: 375,
        });
      });
    },
    userIDResultByIos(jsonStr) {
      this.$message.error("IOS回调");
      // if (jsonStr != "") {
      //   this.user.Id =JSON.parse(jsonStr).data;
      //   this.getQueryUserInfo();
      // } else {
      //   this.$message.error("登录失败");
      // }
    },
    callApp() {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(JSON.stringify({ action: "getUserID" }));
      } else if (isiOS) {
        window.webkit.messageHandlers.callIosGetUserID.postMessage(
          JSON.stringify({ action: "callIosGetUserID" })
        );
      }
    },
  },
};
</script>
<style lang="less" scoped>
.artistsDetails {
  .works {
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 1.5;
      margin-bottom: 15px;
    }
    padding: 15px 0;
    /deep/ul {
      display: inline-block;
      -moz-column-count: 2; /* Firefox */
      -webkit-column-count: 2; /* Safari 和 Chrome */
      column-count: 2;
      -moz-column-gap: 15px;
      -webkit-column-gap: 15px;
      column-gap: 15px;
      li {
        break-inside: avoid;
        .coverImg {
          width: 100%;
          border-radius: 6px;
        }
        .default {
          font-size: 15px;
          font-weight: bold;
          line-height: 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-size: 12px;
          }
          .defaultPrice {
            color: #fe4a4a;
            font-weight: bold;
          }
          img {
          }
        }
      }
    }
  }
  .head_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px 15px;
    background-color: #fff;
    .head {
      .head1 {
        height: 36px;
        width: 36px;
        border-radius: 50px;
        margin-right: 10px;
      }
      span {
        font-size: 15px;
        font-weight: bold;
        line-height: 18px;
        color: #333333;
      }
      .icon_qian {
        height: 14px;
        width: 14px;
        margin-left: 4px;
      }
    }
    .more {
      color: #000000;
      font-size: 24px;
      line-height: 0;
    }
  }
  .imgs {
    padding: 0 5px;
    background-color: #fff;
    img {
      max-width: 100%;
      margin-bottom: 6px;
    }
  }
  .cont {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 1px 15px 15px;
    h5 {
      font-size: 20px;
      font-weight: 800;
      color: #333333;
      line-height: 30px;
      margin-top: 20px;
      margin-bottom: 15px;
    }
    .specs {
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      color: #333333;
      margin-bottom: 15px;
    }
    .fbllz {
      line-height: 18px;
      font-size: 12px;
      margin-bottom: 12px;
      p {
        color: #9b9b9b;
        display: inline-block;
        &::before {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          margin-right: 4px;
          background-size: cover;
          background-repeat: no-repeat;
        }
        + p {
          margin-left: 15px;
        }
        &:nth-child(1) {
          &::before {
            background-image: url("../../../assets/images/icon_time.png");
          }
        }
        &:nth-child(2) {
          &::before {
            width: 15.5px;
            background-image: url("../../../assets/images/icon_eye.png");
          }
        }
        &:nth-child(3) {
          &::before {
            width: 11.5px;
            background-image: url("../../../assets/images/icon_heart.png");
          }
        }
      }
    }
    .price {
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      color: #fe4a4a;
      span {
        font-size: 14px;
      }
    }
    .des {
      font-size: 14px;
      color: #9b9b9b;
      line-height: 18px;
      margin-top: 30px;
    }
    .content {
      font-size: 15px;
      font-weight: bold;
      color: #333333;
      line-height: 26px;
      margin-top: 14px;
      /deep/div {
        width: 100% !important;
      }
      /deep/img {
        max-width: 100% !important;
      }
    }
  }
  .fixed_ship {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 54px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    .ship {
      img {
        &:nth-child(1) {
          width: 23px;
          margin-right: 17px;
        }
        &:nth-child(2) {
          width: 21px;
        }
      }
    }
    .shop {
      font-size: 15px;
      color: #ffffff;
      font-weight: 500;
      width: 110px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3a78f9;
      border-radius: 40px;
    }
  }
}
</style>
